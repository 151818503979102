<template>
    <div class="search-filters" :class="CSSClasses">
        <div v-show="isPhone" class="mobile-filters-sticky">
            <Typography variant="body-display-large" class="mobile-filter-toggler-title">{{ title }}</Typography>

            <Button @click="onMobileFiltersOpen" variant="tertiary" class="mobile-filters-toggle">
                <template #icon>
                    <Icon name="filters" />
                </template>
                <template #accessory>
                    <div v-if="activeFilters.length" class="mobile-filters-count">
                        {{ activeFilters.length }}
                    </div>
                </template>
            </Button>
        </div>

        <Transition name="vue-fade">
            <div v-show="isPhone ? areMobileFiltersToggled : true" class="search-filters-wrapper">
                <div class="mobile-filter-heading">
                    <Typography class="mobile-title" as="h5" variant="h5" transform="capitalize"
                        >{{ $t('filters') }}
                    </Typography>
                    <Button @click="onMobileFiltersClose" variant="tertiary" class="mobile-close-button">
                        <template #icon>
                            <Icon name="close" class="mobile-close-icon" />
                        </template>
                    </Button>
                </div>

                <div class="filters">
                    <template v-for="(value, key) in filters">
                        <IndexFilterMenu
                            v-if="renderedFilters.includes(key) && value"
                            :title="$t(key, 1, { locale: pageLanguage })"
                            :key="key"
                            :items="value"
                            class="filter"
                            :pageLanguage="pageLanguage"
                        >
                        </IndexFilterMenu>
                    </template>
                </div>

                <div class="chips-controls-wrapper">
                    <div class="filter-chip-row" v-if="activeFilters?.length">
                        <div class="filter-chips">
                            <div
                                v-for="filter in activeFilters"
                                :key="filter?.id ?? filter?.title"
                                class="chip-and-sub"
                            >
                                <template v-if="defaultFilters && filter.id === defaultFilterID">
                                    <Chip theme="outline" type="filter" class="chip top-level"
                                        >{{ filter?.title == 'Posts' ? $t('Commentary') : filter?.title }}
                                    </Chip>
                                    <template v-if="filter?.subItems?.filter((item) => item?.isChecked)">
                                        <button
                                            v-for="(subItem, idx) in filter?.subItems?.filter(
                                                (item) => item?.isChecked,
                                            )"
                                            :key="idx"
                                            :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                        >
                                            {{ subItem?.title }}
                                        </button>
                                    </template>
                                </template>
                                <template v-else>
                                    <Chip
                                        theme="outline"
                                        type="filter"
                                        class="chip top-level"
                                        @click="clearSingleFilter(filter)"
                                        >{{ filter?.title == 'Posts' ? $t('Commentary') : filter?.title }}
                                        <Icon name="close" class="chip-icon" />
                                    </Chip>
                                    <template v-if="filter?.subItems?.filter((item) => item?.isChecked)">
                                        <template
                                            v-for="(subItem, idx) in filter?.subItems?.filter(
                                                (item) => item?.isChecked,
                                            )"
                                        >
                                            <button
                                                v-if="subItem?.id !== defaultFilterID"
                                                :key="idx"
                                                :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                                @click="clearSingleFilter(filter, subItem)"
                                            >
                                                {{ subItem?.title }}
                                                <Icon name="close" class="chip-icon" />
                                            </button>
                                            <button
                                                v-if="subItem?.id === defaultFilterID"
                                                :key="idx"
                                                :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                            >
                                                {{ subItem?.title }}
                                            </button>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <Button v-if="!defaultFilters" @click="resetFilters()" class="chips-clear" variant="tertiary"
                            >Clear All</Button
                        >
                    </div>
                    <div class="mobile-filter-controls">
                        <Button v-if="!defaultFilters" @click="onMobileFiltersClear" variant="tertiary">Clear</Button>
                        <Button @click="onMobileFiltersClose" variant="primary">See Results</Button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import centersArray from '@/constants/centers.js';

const emit = defineEmits(['change']);

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    renderedFilters: {
        type: Array,
        default: () => ['topics', 'regions', 'types', 'experts', 'years'],
    },
    defaultFilters: {
        type: Object,
    },
    hasSearch: {
        type: Boolean,
        default: false,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});

const centerStore = useCentersConfigStore();
const { currentCenterTopics: unmappedTopics, currentCenterRegions: unmappedRegions } = storeToRefs(centerStore);
const { data } = await useSearchFilters();
const filterData = data?.value?.searchFilters || {};
const topics = computed(
    () =>
        unmappedTopics?.value?.docs?.map((topic) => {
            return reactive({
                ...topic,
                isChecked: false,
            });
        }) || [],
);

function mappedRegions(data) {
    if (!data) return [];

    const regionItems = data
        .filter((item) => item.type === 'region')
        ?.map((item) => {
            const { id, title } = item;
            return reactive({
                id,
                title,
                subItems: [],
                isChecked: false,
            });
        });

    const countryItems = data.filter((item) => item.type === 'country');
    countryItems.forEach((country) => {
        country.regions.forEach((region) => {
            const associatedRegion = regionItems.find((item) => region.id === item.id);
            if (associatedRegion) {
                const { id, title } = country;
                associatedRegion.subItems.push(
                    reactive({
                        id,
                        title,
                        isChecked: false,
                    }),
                );
            }
        });
    });

    return regionItems;
}
const regions = computed(() => mappedRegions(unmappedRegions?.value?.docs));

const experts = computed(() =>
    filterData?.experts?.docs?.map((expert) => reactive({ ...expert, isChecked: false }) || []),
);

const years = computed(() => mappedStrings(filterData?.years?.docs));
const types = computed(() => mappedStrings(filterData?.types?.docs));
function mappedStrings(arr) {
    return arr?.map((item) => {
        return reactive({
            id: toCamelCase(item),
            title: item,
            isChecked: false,
        });
    });
}

const centers = computed(() => mappedCenters(centersArray));
function mappedCenters(centers) {
    return centers?.map((center) => {
        return reactive({
            id: center.id,
            title: center.title,
            isChecked: false,
        });
    });
}
const filters = reactive({
    topics,
    regions,
    types,
    experts,
    years,
    centers,
});

applyDefaultFilters();
function applyDefaultFilters() {
    if (props.defaultFilters) {
        for (const property in props.defaultFilters) {
            props.defaultFilters[property].forEach((defaultId) => {
                filters[property].forEach((item) => {
                    if (item.id === defaultId) {
                        item.isChecked = true;
                    }
                    if (item.subItems) {
                        item.subItems.forEach((subItem) => {
                            if (subItem.id === defaultId) {
                                subItem.isChecked = true;
                                item.isChecked = true;
                            }
                        });
                    }
                });
            });
        }
    }
}

const defaultFilterID = computed(() => {
    if (props.defaultFilters?.topics) {
        return props.defaultFilters.topics[0];
    } else if (props.defaultFilters?.regions) {
        return props.defaultFilters.regions[0];
    }
});

const areMobileFiltersToggled = ref(false);

function onMobileFiltersOpen() {
    areMobileFiltersToggled.value = true;
}
function onMobileFiltersClose() {
    areMobileFiltersToggled.value = false;
}

const activeFilters = computed(() => {
    const parentArray = [];
    const subArray = [];
    for (const [_, value] of Object.entries(filters)) {
        value.forEach((item) => {
            if (item.isChecked) {
                parentArray.push(item);
            }
            if (!item.isChecked && item.subItems?.length) {
                item.subItems.forEach((item) => {
                    if (item.isChecked) {
                        subArray.push(item);
                    }
                });
            }
        });
    }
    return [...parentArray, ...subArray];
    // return Object.entries(filters).reduce((arr, [_, value]) => {
    //     const checked = value?.filter((item) => item?.isChecked);
    //     return [...arr, ...(checked || [])];
    // }, []);
});

function onMobileFiltersClear() {
    resetFilters();
}

function resetFilters() {
    for (const [_, value] of Object.entries(filters)) {
        value.forEach((item) => {
            item.isChecked = false;
            if (item.subItems?.length) {
                item.subItems.forEach((item) => {
                    item.isChecked = false;
                });
            }
        });
    }
}

function clearSingleFilter(oldFilter, subItem = undefined) {
    const filterToUncheck = Object.values(filters)
        ?.flat()
        .find((item) => item?.title === oldFilter?.title ?? item?.id === oldFilter?.id);
    if (!subItem) {
        if (filterToUncheck === undefined) {
            oldFilter.isChecked = false;
        } else {
            filterToUncheck.isChecked = false;
        }
    } else {
        const subToUncheck = (filterToUncheck?.subItems || [])?.find(
            (item) => item?.title === subItem?.title ?? item?.id === subItem?.id,
        );
        if (subToUncheck) subToUncheck.isChecked = false;
    }
}

// we need to remove this function because we want to separate subItems from the Items being checked
function clearParentlessSubItems() {
    for (const [_, value] of Object.entries(filters)) {
        value.forEach((item) => {
            if (!item.isChecked && item.subItems?.length) {
                item.subItems.forEach((item) => {
                    item.isChecked = false;
                });
            }
        });
    }
}

watch(filters, () => {
    // clearParentlessSubItems();
    const payload = { ...filters };
    for (const [key, value] of Object.entries(payload)) {
        const selectedItems = value.filter((item) => item.isChecked);
        const selectedSubItems = value
            .filter((item) => item.subItems)
            .reduce((acc, curr) => [...acc, ...(curr.subItems.filter((item) => item.isChecked) || [])], []);
        payload[key] = [...selectedItems, ...selectedSubItems].map((item) => {
            if (key === 'years') {
                return Number(item.id);
            } else return item.id;
        });

        if (!payload[key].length) {
            delete payload[key];
        }
    }
    emit('change', payload);
});
const CSSClasses = computed(() => {
    return [props.hasSearch && 'has-search'];
});
</script>

<style lang="scss" scoped>
$max-sub-chip-count: 10;

.search-filters {
    width: 100%;

    &.has-search {
        width: auto;
    }
}

.search-filters-wrapper {
    @include media-query(phone) {
        position: fixed;
        display: block;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color(blanc);
        @include z-index(modal);
        padding: 2.35rem;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
}

.filters {
    display: flex;
    width: 100%;
    z-index: $max-sub-chip-count + 1;
    position: relative;

    @include media-query(phone) {
        flex-direction: column;
    }
}

.filter {
    position: relative;
    flex: 0 0 auto;
    border-inline-end: 1px solid color(noir, default, 0.08);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-inline-end: 1.6rem;

    &:not(:first-child) {
        padding-inline-start: 1.6rem;

        @include media-query(phone) {
            padding-inline-start: 0;
            border-top: 1px solid palette(border);
        }
    }
    &:last-child {
        border-inline-end: 0;
    }

    @include media-query(phone) {
        padding: 0;
        border-inline-end: none;
    }
}

.filter-chip-row {
    display: flex;
    justify-content: space-between;

    @include media-query(phone) {
        display: block;
        width: calc(100vw - 2 * var(--col-gutter));
        overflow-x: scroll;
    }
}

.chips-clear {
    @include hide-on(phone);
}

.filter-chips {
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
    display: flex;
    gap: 1rem;
}

.chip-and-sub {
    display: flex;
    align-items: center;
    margin-inline-end: 0.4rem;

    .chip {
        z-index: $max-sub-chip-count;
        height: 100%;
    }

    @for $i from 0 through $max-sub-chip-count - 1 {
        .subItem-#{$i} {
            z-index: #{($max-sub-chip-count - 1) - $i};
        }
    }
}

.chip {
    align-items: center;

    &:hover {
        text-decoration: line-through;
        .chip-icon {
            fill: color(blue, dark, 1);
        }
    }
}

.top-level:hover {
    background-color: color(blanc) !important;
}

.chip-icon {
    margin-inline-start: 0.8rem;
    display: inline-block;
    align-self: center;
    fill: color(blue, dark, 0.4);
    position: relative;
    top: 0.2rem;
    @include transition(fill, controls);

    svg {
        width: 0.55rem;
        height: 0.55rem;
    }
}

.breadcrumb-pill {
    @include font(mono, regular, 1.2rem, 1.3, false);
    letter-spacing: -0.01em;
    display: inline-block;
    background: #f8f8f8;
    border: 1px solid #d0d0d0;
    --pad-vert: 1.4rem;
    --pad-horiz: 2.4rem;
    --font-size: 1.2rem;
    --line-height: 1.3;
    --pill-height: calc(2 * var(--pad-vert) + var(--font-size) * var(--line-height));
    font-size: var(--fontsize);
    padding: var(--pad-vert) var(--pad-horiz);
    font-size: var(--font-size);
    line-height: var(--line-height);
    border-radius: 100rem;
    position: relative;
    z-index: -1;
    margin-inline-start: calc(-1 * var(--pill-height) - var(--pad-horiz));
    padding-inline-start: calc(var(--pad-horiz) + var(--pill-height) + 0.8rem);
}

.filter-label {
    color: color(blue, dark);
    opacity: 0.7;
    display: inline-flex;
    align-items: center;
    gap: 0.9rem;
}

.filter-count {
    opacity: 0.4;
}

.mobile-filter-heading {
    @include show-on(phone, flex);
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.mobile-filters-sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mobile-close-button {
    padding: 1.4rem;
    border: 0.15rem solid color(blue, dark, 0.4);
    border-radius: 0.5rem;
}

.mobile-close-icon {
    fill: color(blue);
}

.chips-controls-wrapper {
    @include media-query(phone) {
        @include content-section-padding;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0px -0.4rem 1rem 0px #0000001a;
        background-color: color(blanc);
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
}

.mobile-filter-controls {
    @include hide-on(not-phone);
    display: flex;
    justify-content: space-between;
}

.mobile-filters-toggle {
    padding: 1.6rem;
    border: 0.15rem solid color(blue, dark, 0.4);
    border-radius: 0.5rem;
    position: relative;
}

.mobile-filters-count {
    position: absolute;
    @include square(1.4rem);
    border-radius: 50%;
    background: color(teal);
    position: absolute;
    inset-inline-end: -0.7rem;
    top: -0.7rem;
    color: color(blanc);
    font-size: 0.8rem;
    text-align: center;
    line-height: 1.4rem;
}

.mobile-filter-toggler-title {
    display: flex;
    align-items: center;
}
</style>
